// const appUrl = "http://localhost:3005";
const appUrl = "https://api.wccex.org";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  getdata: "pending-kyc-user",
  cpbalancebycoin: "cpbalancebycoin",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  getkycData: "kyc-details",
  verifyIdentity: "update-kyc-status",
  rejectIdentity: "verify-kyc",
  getusers: "getUsers",
  getTotaluser: "user-count",
  getVerified: "approved-kyc-count",
  getPending: "pending-kyc-count",
  getSupport: "support",
  getwithdrawal: "withdrawlrequest",
  getregistration: "counnewreg",
  allkyc: "kyc-details",
  coinlist: "coinList",
  currencyPair: "allsinglecoinlist",
  getSubAdminList: "sub-admin-list",
  AddsubAdmin: "add-new-admin",
  AddTrade: "addnewtrader",
  subadmindelete: "delete_subadmin",
  getallorder: "getOrders",
  addNotify: "add_notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  helplist: "getallhelplist",
  SubadminStatus: "admin_status",
  updateSubadmin: "edit_subadmin",
  tradeStatus: "kycsetstatus",
  getInrWithrawList: "withdraw_request",
  userreqapprove: "confirmInrWithdraw",
  userreqreject: "rejectInrWithdraw",
  addAdTicket: "addAdTicket",
  ticketList: "ticketlist",
  adminsupport: "adminsupport",
  sendmailtouser: "sendmailtouser",
  documentchange: "documentchange",
  transferhistory: "user-trade-history",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  depositrequest: "depositrequest",
  withdrawlrequest: "withdrawlrequest",
  totaldepositrequest: "depositrequest",
  totalwithdrawlrequest: "getWithdrawlReq",
  getmasterbal: "getmasterbal",
  changeseen: "changeseen",
  currencyCoinList: "get-coin",
  walletCoinList: "adminwallet",
  setcoinwithdrawal: "edit-currency",
  getcpaccountinfo: "getcpaccountinfo",
  getcpcoinbalance: "getcpcoinbalance",
  AddCoinPair: "add-pairs",
  AddPairFee: "updatecoinfee",
  getCurrencyPairList: "get-pairs",
  tredingReport: "trading-commission",
  sendFundStatus: "transactionstatus",
  completeWithdrawalRequest: "complete_withdrawal_request",
  PendingWithdrwal: "pending_withdrawal_request",
  CancelledWithdrwal: "cancel_withdrawal_request",
  completeDepositRequest: "complete_deposit_request",
  completePendingRequest: "pending_deposit_request",
  inrdepositreq: "deposit_request",
  confirmInrDeposit: "update_deposit_status",
  rejectInrDeposit: "update_deposit_status",
  getUserWalletList: "select_given_coin",
  userWalletTransfer: "withdrawalcoin",
  setrewardrate: "kyc/setrewardrate",
  updatetdsrate: "updatetdsrate",
  updatemailTamplate: "updatemailTamplate",
  traderreport: "user-list",
  pendingtrader: "pendingtrader",
  fiatwithreq: "fiatwithreq",
  verifiedtrader: "verifiedtrader",
  fiatdepreq: "fiatdepreq",
  addNewCoins: "add-coin",
  getMasterWalletList: "adminwallettransactions",
  admincoinaddress: "admincoinaddress",
  getreceive: "getreceive",
  stakingList: "get_tran",
  getPackageList: "staking_list",
  deletePackage: "delete_staking",
  getPhaseList: "phases",
  // getCoinList: "phases",
  addPackage: "add_staking",
  phaseStatus: "v1/stacking/phase_status",

  transactionstatus: "update_withdrawal_status",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "set_banner_status",
  bannerdelete: "delete-banner",
  BannerStatus: "set_banner_status",
  deleteCoinPair: "delete-pair",
  walletStatus: "withdrawstatus",
  getOtp: "auth/getcode",
  walletTransfer: "/transfertoother",
  addBankAcc: "add_bank_details",
  getAccDetails: "admin_bank_details",
  fundsTransfer: "debit_credit",
  getCoinList: "get-coin",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  updateStatus: 'update_status',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  MasterAccount: 'master-account',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  editPackage: "edit_staking",
  getAllProject: 'get_all_project',
  createProject: 'create_project',
  deleteProject: 'delete_project',
  updateProject: 'update_project',
  getCommitData: 'get_all_user_commits',
  createBroker: 'add_broker',
  brokerList: 'get_broker',
  addVersion: 'add_version',
  get_supports: 'get_supports',
  all_coins_listing: 'all_coins_listing',
  single_coin_listing: 'single_coin_listing',
  update_coin_listing_status: 'update_coin_listing_status',
  getUserTransaction: 'getUserTransaction',
  orderBook: 'orderBook',

  verifyBankDetails: "verify-bank-details",
  pendingBankDetails: "pending-bank-details",
  approveBankDetails: "approve-bank-details",
  rejectBankDetails: "reject-bank-details",
  pendingUPIDetails: "pending-upi-details",
  approveUPIDetails: "approve-upi-details",
  rejectUPIDetails: "reject-upi-details",
  verifyUPIDetails: "verify-upi-details",
  p2pCoinList: "p2p_coin_list",
  fiatCurrencyList: "fiat_currency_list",
  all_p2p_orders: "all_p2p_orders",
  add_fiat: "add_fiat",
  remove_currency: "remove_currency",
  getAllTickets: 'get-all-tickets',
  orderDetails: 'order_details',
  replyTicket: "reply-ticket",
  statusToSettle: "statusToSettle",
  updateTicketStatus: "update-ticket-status",
  get_support_by_id: "get_support_by_id",
  get_messages: "get_user_messages",
  
  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/v1/admin/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  baseFormUrl: `${appUrl}/v1/admin/launchpad/`,
  basebroker: `${appUrl}/v1/user/`,
  basestaking: `${appUrl}/v1/staking/`,
  baseP2P: `${appUrl}/v1/p2p/`,
  baseSupport: `${appUrl}/v1/support/`,
};

import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";

const UserBank = () => {
    const [data, setData] = useState([]);
    const [slectedBankType, setSlectedBankType] = useState("Pending");

    const dateFilter = (row) => {
        return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
    };
    const status = (row) => {
        return (
            <div className=" d-flex gap-2 " >
                {row?.verified === 0 ? <>
                    <button className="btn-success  btn btn-sm" type="button" onClick={() => VerifyBankDetails(row?._id, '1')}>Approve
                    </button>
                    <button className="btn-danger  btn btn-sm" type="button" onClick={() => VerifyBankDetails(row?._id, '2')}>Reject
                    </button></> : row?.verified === 1 ? <span className=" text-success" >Approved </span> : <span className="text-danger" >Rejected </span>}
            </div>
        );
    };

    const columns = [
        { name: "Date", wrap: true, sort: true, selector: dateFilter },
        { name: "User ID", wrap: true, selector: row => row.user_id, },
        { name: "Name", wrap: true, selector: row => row.account_holder_name, },
        { name: "Account Number", wrap: true, sort: true, selector: row => row.account_number, },
        { name: "Bank Name",  wrap: true,sort: true, selector: row => row.bank_name, },
        { name: "Branch Namer",  wrap: true,sort: true, selector: row => row.branch_name, },
        { name: "IFSC Code", wrap: true, sort: true, selector: row => row.ifsc_code, },
        { name: "Status", wrap: true, width: "200px", selector: status },
    ];

    useEffect(() => {
        handleData("Pending");
    }, []);

    const VerifyBankDetails = async (id, status) => {
        await AuthService.verifyBankDetails(id, status).then(async result => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Status Updated")
                    handleData(slectedBankType);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }

    const handleData = async (type) => {
        setSlectedBankType(type)
        try {
            let result;
            LoaderHelper.loaderStatus(true);
            if (type === "Approved") result = await AuthService.approveBankDetails()
            else if (type === "Pending") result = await AuthService.pendingBankDetails()
            else if (type === "Rejected") result = await AuthService.rejectBankDetails()

            if (result?.success) {
                setData(result?.data?.reverse());
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };



    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className=" fa fa-user-slash"></i>
                                        </div>
                                        User Bank Accounts
                                    </h1>
                                </div>
                                <div className="col-2 mt-4" style={{ color: "#69707a" }}>
                                    <select className="form-control"
                                        value={slectedBankType}
                                        onChange={(e) => handleData(e.target.value)}>
                                        <option>Pending</option>
                                        <option>Approved</option>
                                        <option>Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            User Bank List
    
                        </div>
                        <div className="card-body">
                            <>
                                {data.length === 0 ? (
                                    <h6 className="ifnoData">
                                        <img src="/assets/img/no-data.png" /> <br /> No Data
                                        Available
                                    </h6>
                                ) : (
                                    <div class="table-responsive">
                                        <DataTableBase columns={columns} data={data} />
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default UserBank;

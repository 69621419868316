import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";

const UserUPI = () => {
    const [data, setData] = useState([]);
    const [slectedBankType, setSlectedBankType] = useState("Pending");

    const dateFilter = (row) => {
        return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
    };
    const status = (row) => {
        return (
            <div className=" d-flex gap-2 " >
                {row?.verified === 0 ? <>
                    <button className="btn-success  btn btn-sm" type="button" onClick={() => VerifyUPIDetails(row?._id, '1')}>Approve
                    </button>
                    <button className="btn-danger  btn btn-sm" type="button" onClick={() => VerifyUPIDetails(row?._id, '2')}>Reject
                    </button></> : row?.verified === 1 ? <span className=" text-success" >Approved </span> : <span className="text-danger" >Rejected </span>}
            </div>
        );
    };

    const image = (row) => {
        return (
            <img style={{ width: '40%', height: 'auto' }}
                className="table-img" src={ApiConfig.appUrl + row?.upi_image} />
        );
    };



    const columns = [

        { name: "User Id", selector: row => row.user_id, },
        { name: "Upi Id", sort: true, selector: row => row.upi_id, },
        { name: "Upi Image", sort: true, selector: image, },
        { name: "Date", sort: true, selector: dateFilter },
        { name: "Status", sort: true, selector: status },
    ];

    useEffect(() => {
        handleData("Pending");
    }, []);

    const VerifyUPIDetails = async (id, status) => {
        await AuthService.verifyUPIDetails(id, status).then(async result => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Status Updated")
                    handleData(slectedBankType);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }

    const handleData = async (type) => {
        setSlectedBankType(type)
        try {
            let result;
            LoaderHelper.loaderStatus(true);
            if (type === "Approved") result = await AuthService.approveUPIDetails()
            else if (type === "Pending") result = await AuthService.pendingUPIDetails()
            else if (type === "Rejected") result = await AuthService.rejectUPIDetails()

            if (result?.success) {
                setData(result?.data?.reverse());
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className=" fa fa-user-slash"></i>
                                        </div>
                                        User UPI Details
                                    </h1>
                                </div>
                                <div className="col-2 mt-4" style={{ color: "#69707a" }}>
                                    <select className="form-control"
                                        value={slectedBankType}
                                        onChange={(e) => handleData(e.target.value)}>
                                        <option>Pending</option>
                                        <option>Approved</option>
                                        <option>Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            User UPI Details
                        </div>
                        <div className="card-body">
                            <>
                                {data.length === 0 ? (
                                    <h6 className="ifnoData">
                                        <img src="/assets/img/no-data.png" /> <br /> No Data
                                        Available
                                    </h6>
                                ) : (
                                    <div class="table-responsive">
                                        <DataTableBase columns={columns} data={data} />
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default UserUPI;
